

























import { Component, Vue } from "vue-property-decorator";
import { mapGetters, mapState } from 'vuex';
import { Action } from 'vuex-class';
import RedCredentials from '@/models/RedCredentials';

@Component({
  computed: {
    ...mapGetters('red', {
      credentials: 'getCredentials'
    })
  }
})
export default class RedCredentialsPage extends Vue {

  protected credentials!: RedCredentials
  loading: Boolean = true
  saving: Boolean = false
  
  @Action('fetchCredentials', { namespace: 'red' }) fetchCredentials: any;
  @Action('updateCredentials', { namespace: 'red' }) updateCredentials: any;

  async mounted() {
    try {
      await this.fetchCredentials()
    } catch (err) {
      console.log("# err", err)
      this.$notify({ type: 'error', title: err.error, text: err.message })
    } finally {
      this.loading = false
    }
  }

  submit() {
    this.saving = true
    this.updateCredentials({credentials: this.credentials}).then((result: any) => {
      this.$notify({ type: 'success', text: 'Credentials successfully updated' })
      this.saving = false
      this.$router.push({ name: 'index' })
    })
  }
}
